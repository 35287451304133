import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { imeiSelector } from '../../Components/Hooks/hbb/hbbReducer';
import Swal from 'sweetalert2';
import { useIMEI } from '../../Components/Hooks/imei/useIMEI';

const IMEI = () => {
  const { blockIMEI, unblockIMEI } = useIMEI();
  const imei = useSelector(imeiSelector);

  const block = () => {
    if (!imei) {
      Swal.fire({
        title: 'IMEI faltante',
        icon: 'error'
      });
      return;
    }

    Swal.fire({
      icon: 'warning',
      title: '¿Bloquear IMEI?',
      showCancelButton: true,
      confirmButtonText: 'Desactivar IMEI',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: 'true',
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await blockIMEI(imei);
          return { blocked: true };
        } catch (error) {
          return { blocked: false };
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (result.value.blocked) {
          Swal.fire({
            title: 'IMEI Desactivado',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: 'IMEI no pudo ser desactivado',
            icon: 'error'
          });
        }
      }
    });
  };

  const unblock = () => {
    if (!imei) {
      Swal.fire({
        title: 'IMEI faltante',
        icon: 'error'
      });
      return;
    }

    Swal.fire({
      icon: 'warning',
      title: '¿Desbloquear IMEI?',
      showCancelButton: true,
      confirmButtonText: 'Desactivar IMEI',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: 'true',
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await unblockIMEI(imei);
          return { blocked: true };
        } catch (error) {
          return { blocked: false };
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (result.value.blocked) {
          Swal.fire({
            title: "IMEI desactivado",
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "IMEI no pudo ser desactivado",
            icon: "error"
          });
        }
      }
    });
  };

  return (<>
    <Button color="primary" size="lg" className="mb-2" outline block onClick={() => block()}>
      <i className="bx bxs-lock-alt" style={{marginRight: 10}}></i>
      Bloquear IMEI
    </Button>
    <Button color="success" size="lg" className="mt-2" outline block onClick={() => unblock()}>
      <i className="bx bxs-lock-open-alt" style={{marginRight: 10}}></i>
      Desbloquear IMEI
    </Button>
  </>);
};

export default IMEI;
