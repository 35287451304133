import { useMutation } from '@tanstack/react-query';
import { postEnableTraffic } from '../../../helpers/fakebackend_helper';

export const useEnableTraffic = () => {
  const { mutateAsync: enableTraffic, data: enableTrafficResult, isPending: loadingEnableTraffic, isSuccess, isError } = useMutation({
    mutationFn: async (msisdn) => await postEnableTraffic({ msisdn, reason: 1 })
  });

  return { enableTraffic, loadingEnableTraffic, isSuccess, isError };
};
