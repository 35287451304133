import React from "react";
import { Card, CardBody } from "reactstrap";
import { useLoggedIn } from '../../../Components/Hooks/useLoggedIn';

const ProfileCard = () => {
  const { userInfo } = useLoggedIn();

  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="p-4">
          <div className="text-center">
            <h5 className="fs-17 mb-1">{ userInfo?.display_name }</h5>
            <p className="text-muted mb-0">Usuario: { userInfo?.name }</p>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProfileCard;
