import { useMutation } from '@tanstack/react-query';
import { postDeviceInfo } from '../../../helpers/fakebackend_helper';
import { useDispatch } from 'react-redux';
import { deviceInfoFailure, deviceInfoFetch, deviceInfoSuccess } from './deviceInfoReducer';

export const useDeviceInfo = () => {
  const dispatch = useDispatch();

  const { mutate: getDeviceInfo } = useMutation({
    mutationFn: async (msisdn) => await postDeviceInfo({ msisdn }),
    onMutate: () => dispatch(deviceInfoFetch()),
    onSuccess: (res) => dispatch(deviceInfoSuccess(res?.altanresponse)),
    onError: () => dispatch(deviceInfoFailure())
  });

  return { getDeviceInfo };
};
