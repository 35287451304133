import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

const TaxAddressForm = () => {
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 py-1">
          <div>
            <Label htmlFor="streetNameInput" className="form-label">
              Calle
            </Label>
            <Input type="text" className="form-control" id="streetNameInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="extNumInput" className="form-label">
              Número exterior
            </Label>
            <Input type="text" className="form-control" id="extNumInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="intNumInput" className="form-label">
              Número interior
            </Label>
            <Input type="text" className="form-control" id="intNumInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="colonyInput" className="form-label">
              Colonia
            </Label>
            <Input type="text" className="form-control" id="colonyInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="cityInput" className="form-label">
              Ciudad
            </Label>
            <Input type="text" className="form-control" id="cityInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="minicipioInput" className="form-label">
              Municipio
            </Label>
            <Input type="text" className="form-control" id="minicipioInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="stateInput" className="form-label">
              Estado
            </Label>
            <Input type="text" className="form-control" id="stateInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="countryInput" className="form-label">
              País
            </Label>
            <Input type="text" className="form-control" id="countryInput" />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="zipCodeInput" className="form-label">
              Código Postal
            </Label>
            <Input type="text" className="form-control" id="zipCodeInput" />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TaxAddressForm;
