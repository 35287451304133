import React, { useState } from "react";

const Navdata = () => {
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Inicio",
      icon: "las la-home",
      link: "/#",
      stateVariables: iscurrentState === 'Dashboard',
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "ventas",
      label: "Ventas",
      icon: "las la-coins",
      link: "/ventas",
      stateVariables: iscurrentState === 'Sales',
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Sales");
        updateIconSidebar(e);
      },
    },
    {
      id: "uf",
      label: "Consulta de perfil de UF",
      icon: "las la-search",
      link: "/consulta-uf",
      stateVariables: iscurrentState === 'ConsultaUF',
      click: function (e) {
        e.preventDefault();
        setIscurrentState("ConsultaUF");
        updateIconSidebar(e);
      },
    },
    {
      id: "pre-registro",
      label: "Pre-registro",
      icon: "las la-pen-square",
      link: "/pre-registro",
      stateVariables: iscurrentState === 'Pre-registro',
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Pre-registro");
        updateIconSidebar(e);
      },
    },
    {
      id: "portabilidad",
      label: "Portabilidad",
      icon: "las la-mobile-alt",
      link: "/portabilidad",
      stateVariables: iscurrentState === 'Portability',
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Portability");
        updateIconSidebar(e);
      },
    },
    {
      id: "perfil",
      label: "Perfil",
      icon: "las la-user",
      link: "/perfil",
      stateVariables: iscurrentState === 'Profile',
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Profile");
        updateIconSidebar(e);
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
