import { useEffect, useMemo } from "react";
import { TOKEN_KEY, USER_KEY } from "../../helpers/api_helper";
import { useQuery } from '@tanstack/react-query';
import { getIsAliveToken } from '../../helpers/fakebackend_helper';
import { useNavigate } from 'react-router-dom';

const useLoggedIn = () => {
  const navigate = useNavigate();
  const userToken = sessionStorage.getItem(TOKEN_KEY);

  const { isSuccess: isTokenAlive, isFetching: loading } = useQuery({
    queryKey: ['user-token'],
    queryFn: async () => await getIsAliveToken(),
    retry: false,
    enabled: !!userToken
  });

  const userInfo = useMemo(() => {
    const user = sessionStorage.getItem(USER_KEY);
    try {
      return JSON.parse(user);
    } catch (error) {
      return null;
    }
  }, []);

  const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(USER_KEY);
    navigate('/login');
  };

  const isLoggedIn = useMemo(() => {
    return userToken && isTokenAlive;
  }
  , [userToken, isTokenAlive, loading]);

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      logout();
    }
  }, [userToken, isLoggedIn]);

  return { loading, userToken, isLoggedIn, userInfo, logout };
};

export { useLoggedIn };
