import { getOfferingList } from '../../helpers/fakebackend_helper';
import { useQuery } from '@tanstack/react-query';
import { useState, useMemo } from 'react';

export const useOfferings = () => {
  const [period, setPeriod] = useState('');

  const { data: offeringsResult, isLoading: loading } = useQuery({
    queryKey: ['offerings'],
    queryFn: async () => await getOfferingList(),
    select: (offeringsResponse) => offeringsResponse.result,
    staleTime: Infinity
  });

  const offerings = useMemo(() => {
    if (offeringsResult) {
      return parseOfferings(offeringsResult);
    }
    return null;
  }, [offeringsResult]);

  const filteredOfferings = useMemo(() => {
    if (offeringsResult) {
      return getFilteredOfferings(period, offeringsResult);
    }
    return null;
  }, [offeringsResult, period]);

  return { offerings, filteredOfferings, setPeriod, loading };
};

/**
 * @param {*} offerings Respuesta con lista de ofertas
 * @returns Objeto con todas las ofertas con el id como key
 */
function parseOfferings (offerings) {
	const offeringsObj = {};
	for (let i = 0; i < offerings.length; i++)
		offeringsObj[offerings[i].offer_id] = offerings[i].display_name;
	return offeringsObj;
};

function getFilteredOfferings(period, offerings) {
	return offerings.filter(offering => {
    const offeringTitle = offering.display_name;
    switch (period) {
      case "7d":
        return textContains(offeringTitle, "7 días");
      case "15d":
        return textContains(offeringTitle, "15 días");
      case "30d":
        return !textContains(offeringTitle, "7 días") &&
        !textContains(offeringTitle, "15 días") &&
        !textContains(offeringTitle, "12 meses");
      case "12m":
        return textContains(offeringTitle, "12 meses");
			}
		});

	function textContains(text, subtext) {
		return text.toLowerCase().indexOf(subtext.toLowerCase()) != -1;
	}
}
