import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import DeleteDeviceModal from "./DeleteDeviceModal";

export const ApplicationsTable = () => {
  const headers = [
    { text: "Dispositivo", value: "dispositivo" },
    { text: "Identificador", value: "identificador" },
    { text: "Nip", value: "nip" },
    { text: "Estatus", value: "estatus" },
    { text: "Acciones", value: "actions" },
  ];

  const items = [
    {
      id: 1,
      dispositivo: "PC APP WEB",
      identificador: null,
      nip: null,
      estatus: true,
    },
    {
      id: 2,
      dispositivo: "MOVIL ANDROID	",
      identificador: null,
      nip: null,
      estatus: true,
    },
  ];

  const [deviceToDelete, setDeviceToDelete] = useState(null);
  const [isOpenDeleteDeviceModal, setIsOpenDeleteDeviceModal] = useState(false);
  const onDeleteDevice = (device) => {
    console.log(device);
    setDeviceToDelete(device);
    setIsOpenDeleteDeviceModal(true);
  };

  return (
    <Card>
      <CardBody>
        <div className="table-responsive table-card">
          <table className="table align-middle table-borderless table-centered table-nowrap mb-0">
            <thead className="text-muted table-light">
              <tr>
                {headers.map((header) => (
                  <th key={header.value} scope="col">
                    {header.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.length ? (
                (items || []).map((item, index) => (
                  <tr key={index}>
                    {headers.map((header) => (
                      <td
                        key={header.value + "-" + index}
                        className="text-center"
                      >
                        {header.value == "estatus" ? (
                          <i className="mx-auto la la-check"></i>
                        ) : header.value == "actions" ? (
                          <i
                            className="mx-auto la la-ban cursor-pointer"
                            onClick={() => onDeleteDevice(item)}
                          ></i>
                        ) : (
                          item[header.value]
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No hay registros existentes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isOpenDeleteDeviceModal && deviceToDelete && (
            <DeleteDeviceModal
              isOpen={isOpenDeleteDeviceModal}
              device={deviceToDelete}
              onClose={() => setIsOpenDeleteDeviceModal(false)}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};
