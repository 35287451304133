import { Card, CardBody, Spinner } from 'reactstrap';

const CardSpinner = () => {
  return (
    <Card className="w-100">
      <CardBody className="d-flex align-items-center justify-content-center">
        <Spinner className="me-2" />
      </CardBody>
    </Card>
  );

};

export default CardSpinner;
