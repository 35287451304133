import { useSelector } from 'react-redux';
import Gauge from '../Query/Gauge';
import { Card, CardBody, Spinner } from "reactstrap";
import { selectGaugeDetails } from '../../Components/Hooks/msisdn-status/msisdnStatusReducer';
import CardSpinner from '../../Components/UI/CardSpinner';

const DashboardGauge = () => {
  const data = useSelector(selectGaugeDetails);

  return (<>
    { data.loading && <CardSpinner /> }
    { !data.loading && data.gaugeData &&
      <Card className="w-100"><CardBody>
        <div style={{position: 'relative'}}>
          <Gauge data={data.gaugeData?.dataR} labels={data.gaugeData?.labelR} name={data.gaugeData?.gaugeLabel}/>
        </div>
      </CardBody></Card>
    }
  </>);
};

export default DashboardGauge;
