import { createSelector, createSlice } from '@reduxjs/toolkit';

const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState: {
    deviceInfo: null,
    loading: false,
  },
  reducers: {
    deviceInfoFetch: (state) => {
      state.loading = true;
      state.deviceInfo = null;
    },
    deviceInfoSuccess: (state, action) => {
      state.loading = false;
      state.deviceInfo = action.payload
    },
    deviceInfoFailure: (state) => {
      state.loading = false;
      state.deviceInfo = null;
    },
  },
});

const selectBrand = createSelector(state => state.DeviceInfo, (d) => d?.deviceInfo?.brand);
const selectDeviceFeatures = createSelector(state => state.DeviceInfo, (d) => d?.deviceInfo?.deviceFeatures);
const selectIMEI = createSelector(state => state.DeviceInfo, (d) => d?.deviceInfo?.imei);
const selectModel = createSelector(state => state.DeviceInfo, (d) => d?.deviceInfo?.model);
const selectLoading = createSelector(state => state.DeviceInfo, (d) => d?.loading);
const isThereDeviceInfoSelector = createSelector(state => state.DeviceInfo, (d) => !!d?.deviceInfo);

const { deviceInfoFailure, deviceInfoFetch, deviceInfoSuccess } = deviceInfoSlice.actions;
export default deviceInfoSlice.reducer;
export { deviceInfoFailure, deviceInfoFetch, deviceInfoSuccess, selectBrand, selectDeviceFeatures, selectIMEI, selectModel, selectLoading, isThereDeviceInfoSelector };
