import { useDispatch, useSelector } from 'react-redux';
import { useHBBQuery } from '../../Components/Hooks/hbb/useHBBQuery';
import { createSelector } from 'reselect';
import { useDeviceInfo } from '../../Components/Hooks/device-info/useDeviceInfo';
import { useMSISDNStatus } from '../../Components/Hooks/msisdn-status/useMSISDNStatus';
import { selectCurrentMSISDN, setCurrentMSISDN } from './searchTextReducer';
import { isThereStatusInfoSelector } from '../../Components/Hooks/msisdn-status/msisdnStatusReducer';
import { isThereAnyHBBInfoSelector } from '../../Components/Hooks/hbb/hbbReducer';
import { isThereDeviceInfoSelector } from '../../Components/Hooks/device-info/deviceInfoReducer';
import { isThereUserInfoSelector } from '../../Components/Hooks/user-info/userInfoReducer';
import { useUserInfo } from '../../Components/Hooks/user-info/useUserInfo';
import { useComments } from '../../Components/Hooks/comments/useComments';
import { resetComments } from '../../Components/Hooks/comments/commentsReducer';

export const useDashboard = () => {
  const dispatch = useDispatch();
  const { getHBBInfo } = useHBBQuery();
  const { getDeviceInfo } = useDeviceInfo();
  const { getMSISDNStatus } = useMSISDNStatus();
  const { getUserInformation } = useUserInfo();
  const { getComments } = useComments();

  const msisdnStatus = (state) => state.MSISDNStatus;
  const hbbState = (state) => state.HBBInfo;
  const deviceInfoState = (state) => state.DeviceInfo;

  const isAnyLoadingSelector = createSelector(msisdnStatus, hbbState, deviceInfoState,
    (status, hbb, deviceInfo) => status.loading || hbb.loading || deviceInfo.loading);
  const hasAnyDataSelector = createSelector([
    isThereStatusInfoSelector,
    isThereAnyHBBInfoSelector,
    isThereDeviceInfoSelector,
    isThereUserInfoSelector],
    (statusState, hbbState, deviceInfo, userInfo) => statusState || hbbState || deviceInfo || userInfo);
  const hasDataAndFinishedLoadingSelector = createSelector(isAnyLoadingSelector, hasAnyDataSelector, (loading, hasData) => hasData && !loading);


  const hasDataAndReady = useSelector(hasDataAndFinishedLoadingSelector);
  const currentMSISDN = useSelector(selectCurrentMSISDN);

  const executeSearch = (msisdn) => {
    dispatch(setCurrentMSISDN(msisdn));
    dispatch(resetComments());
    getMSISDNStatus(msisdn);
    getHBBInfo(msisdn);
    getDeviceInfo(msisdn);
    getUserInformation(msisdn);
    getComments({ page: 0 });
  };

  return { executeSearch, currentMSISDN, hasDataAndReady };
};
