import { useFormik } from 'formik';
import { Button, Card, CardBody, CardText, CardTitle, Input, InputGroup, Spinner } from 'reactstrap';
import * as Yup from "yup";
import { useComments } from '../../Components/Hooks/comments/useComments';
import { useSelector } from 'react-redux';
import { selectLoadingAddNewComment, selectComments, selectCommentsPage } from '../../Components/Hooks/comments/commentsReducer';
import { formatDateWithTime } from '../../helpers/date_helper';
import InfiniteScroll from 'react-infinite-scroll-component';

const Followup = () => {
  const { createComment, getComments } = useComments();
  const comments = useSelector(selectComments);
  const commentsPage = useSelector(selectCommentsPage);
  const loadingNewComment = useSelector(selectLoadingAddNewComment);

  const fetchNext = () => {
    if (commentsPage.hasNext) {
      getComments({ page: commentsPage.currentPage + 1 });
    }
  };

  const commentForm = useFormik({
      initialValues: {
        comment: '',
      },
      validationSchema: Yup.object({
        comment: Yup.string().required("Por favor ingrese el comentario"),
      }),
      onSubmit: (comment) => {
        createComment(comment);
      },
  });

  const Entry = ({ username, createDate, text  }) => (
    <Card body outline color="secondary" className="border" style={{maxWidth: '30em'}}>
      <CardTitle tag="h6">
        { /**<div className="d-flex fs-14">
          <i className="bx bx-message-dots fs-16"></i>
          { username }
          </div>**/ }
        <div className="d-flex fs-14">
          <i className="bx bxs-calendar fs-16"></i>
          { formatDateWithTime(createDate) }
        </div>
      </CardTitle>
      <CardText>{ text }</CardText>
    </Card>
  );

  return (
    <Card>
      <CardBody>
        <InputGroup className="mb-3">
          <Input name="comment"
            onChange={commentForm.handleChange}
            onBlur={commentForm.handleBlur}
            invalid={ commentForm.touched.comment && !!commentForm.errors.comment }
            value={ commentForm.values.comment || '' }
            placeholder="Ingresa información de seguimiento aquí"/>
          <Button color="primary d-flex justify-content-center align-items-center"
            disabled={loadingNewComment}
            onClick={() => commentForm.submitForm()}>
            { loadingNewComment ?
              <Spinner className="me-2" size="sm" /> :
              <i className="bx bx-message-add fs-22"></i>
            }
          </Button>
        </InputGroup>

        { comments && commentsPage &&
          <InfiniteScroll dataLength={commentsPage.totalItems}
            height={400}
            next={fetchNext}
            hasMore={commentsPage.hasNext}
            loader={<Spinner className="me-2" />}
          >
            {
              comments.map(commentItem =>
                <Entry username={''} createDate={ commentItem.created_at }
                text={ commentItem.comment } key={'comment'+commentItem.id}/>)
            }
          </InfiniteScroll>
        }
      </CardBody>
    </Card>
  );
};

export default Followup;
