import { useSelector } from 'react-redux';
import { selectMSISDNStatus } from '../../Components/Hooks/msisdn-status/msisdnStatusReducer';
import { formatAmount } from '../../helpers/numbers_helper';
import { AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Table, UncontrolledAccordion } from 'reactstrap';
import { formatDate } from '../../helpers/date_helper';
import CardSpinner from '../../Components/UI/CardSpinner';

const Sums = () => {
  const msisdnStatus = useSelector(selectMSISDNStatus);

  const ConsumedBreakDown = ({ status, includeTotal = true }) => (
    <Table responsive hover size="sm" className="text-body">
      <thead>
        <tr>
          <th></th>
          <th>Total(mb)</th>
          <th>Consumido(mb)</th>
          <th>Disponible(mb)</th>
        </tr>
      </thead>
      <tbody>
        <Section statusArr={status.data} total={status.totalData}/>
        { includeTotal && <SectionTotal total={status.totalData} /> }
        <Section statusArr={status.sms} total={status.totalSMS}/>
        { includeTotal && <SectionTotal total={status.totalSMS} /> }
        <Section statusArr={status.min} total={status.totalMin}/>
        { includeTotal && <SectionTotal total={status.totalMin} /> }
      </tbody>
    </Table>
  );

  const Section = ({ statusArr }) => (<>
    {
      statusArr.map((item, index) => (
        <tr key={item+index}>
          <th scope="row">{ item.description }</th>
          <td>{ formatAmount(Number(item.initialAmt)) }</td>
          <td>{ formatAmount(Number(item.usedAmt)) }</td>
          <td>{ formatAmount(Number(item.unusedAmt)) }</td>
        </tr>
      ))
    }
  </>);

  const SectionTotal = ({ total }) => (
    <tr>
      <th scope="row" className="fw-bold">Total</th>
      <td className="fw-bold">{ formatAmount(total.initialAmt) }</td>
      <td className="fw-bold">{ formatAmount(total.usedAmt) }</td>
      <td className="fw-bold">{ formatAmount(total.unusedAmt) }</td>
    </tr>
  );

  return (<>
    { msisdnStatus.loading && <CardSpinner /> }
    {
      !msisdnStatus.loading && msisdnStatus.statusDetails &&
      <Card>
        <CardBody>
          <h4>Detalles de los servicios</h4>
          <UncontrolledAccordion defaultOpen="0" style={{color: 'red'}}>
          {
            msisdnStatus.statusDetails.map((status, index) =>
              <AccordionItem key={'consumed'+index}>
                <AccordionHeader targetId={'target'+index}>
                  <div className="d-flex flex-column">
                    <h5>{ status.title || '-' }</h5>
                    <div>Inicia el: { formatDate(status.effectiveDate) }</div>
                    <div>Expira el: { formatDate(status.expireDate) }</div>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={'target'+index}>
                  <ConsumedBreakDown key={'status'+index} status={status} />
                </AccordionBody>
              </AccordionItem>
            )
          }
          </UncontrolledAccordion>
        </CardBody>
      </Card>
    }
  </>);
};

export default Sums;
