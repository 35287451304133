import { createSelector, createSlice } from '@reduxjs/toolkit';

const searchTextSlice = createSlice({
  name: 'searchText',
  initialState: {
    searchText: '',
    currentMSISDN: null
  },
  reducers: {
    setCurrentMSISDN: (state, action) => {
      state.currentMSISDN = action.payload;
    },
  },
});

export const selectCurrentMSISDN = createSelector(state => state.SearchText, (text) => text.currentMSISDN);

export const { setCurrentMSISDN } = searchTextSlice.actions;
export default searchTextSlice.reducer;
