import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { useSuspendTraffic } from '../../Components/Hooks/suspend-traffic/useSuspendTraffic';
import { useSelector } from 'react-redux';
import { selectCurrentMSISDN } from './searchTextReducer';
import { useEnableTraffic } from '../../Components/Hooks/enable-traffic/useEnableTraffic';

const Activation = () => {
  const msisdn = useSelector(selectCurrentMSISDN);
  const { suspendTraffic } = useSuspendTraffic();
  const { enableTraffic } = useEnableTraffic();

  const suspend = () => {
    Swal.fire({
      icon: 'warning',
      title: '¿Desactivar línea?',
      showCancelButton: true,
      confirmButtonText: 'Desactivar línea',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: 'true',
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await suspendTraffic(msisdn);
          return { suspended: true };
        } catch (error) {
          return { suspended: false };
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (result.value.suspended) {
          Swal.fire({
            title: "Línea desactivada",
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "La línea no pudo ser desactivada",
            icon: "error"
          });
        }
      }
    });
  };

  const enable = () => {
    Swal.fire({
      icon: 'warning',
      title: '¿Reactivar línea?',
      showCancelButton: true,
      confirmButtonText: 'Reactivar línea',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: 'true',
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await enableTraffic(msisdn);
          return { enabled: true };
        } catch (error) {
          return { enabled: false };
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (result.value.enabled) {
          Swal.fire({
            title: "Línea reactivada",
            icon: "success"
          });
        } else {
          Swal.fire({
            title: "La línea no pudo ser reactivada",
            icon: "error"
          });
        }
      }
    });
  };

  return (<>
    <Button color="primary" size="lg" className="mb-2" block onClick={() => suspend()}>
      <i className="bx bxs-message-rounded-minus" style={{marginRight: 10}}></i>
      Desactivar línea
    </Button>
    <Button color="success" size="lg" className="mt-2" block onClick={() => enable()}>
      <i className="bx bxs-message-rounded-add" style={{marginRight: 10}}></i>
      Reactivar línea
    </Button>
  </>);
};

export default Activation;
