import { createSelector, createSlice } from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    userInfo: null,
    loading: false,
  },
  reducers: {
    userInfoFetch: (state) => {
      state.loading = true;
      state.userInfo = null;
    },
    userInfoFetchSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload
    },
    userInfoFetchFailure: (state) => {
      state.loading = false;
      state.userInfo = null;
    },
  },
});

const isThereUserInfoSelector = createSelector(s => s.UserInfo, (d) => !!d?.userInfo);
const userInfoSelector = createSelector(s => s.UserInfo, userInfoState => {
  const userDetails = userInfoState.userInfo?.result?.result?.[0];
  return {
    name: userDetails?.display_name,
    email: userDetails?.email,
    loading: userInfoState.loading,
  };
});

const { userInfoFetch, userInfoFetchSuccess, userInfoFetchFailure } = userInfoSlice.actions;

export default userInfoSlice.reducer;
export { userInfoFetch, userInfoFetchSuccess, userInfoFetchFailure, isThereUserInfoSelector, userInfoSelector };
