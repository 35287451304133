import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { userInfoFetch, userInfoFetchSuccess, userInfoFetchFailure } from './userInfoReducer';
import { getUserInfo } from '../../../helpers/fakebackend_helper';

export const useUserInfo = () => {
  const dispatch = useDispatch();

  const { mutate: getUserInformation } = useMutation({
    mutationFn: async (msisdn) => await getUserInfo({ msisdn }),
    onMutate: () => dispatch(userInfoFetch()),
    onSuccess: (res) => dispatch(userInfoFetchSuccess(res)),
    onError: () => dispatch(userInfoFetchFailure())
  });

  return { getUserInformation };
};
