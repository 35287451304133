import { createSelector, createSlice } from '@reduxjs/toolkit';
import { formatGaugeValues, getDetails } from './formatMSISDNValues';

const msisdnStatusSlice = createSlice({
  name: 'msisdnStatus',
  initialState: {
    status: null,
    loading: false,
  },
  reducers: {
    msisdnStatusFetch: (state) => {
      state.loading = true;
      state.status = null;
    },
    fetchMSISDNStatusSuccess: (state, action) => {
      state.loading = false;
      state.status = action.payload
    },
    fetchMSISDNStatusFailure: (state) => {
      state.loading = false;
      state.status = null;
    },
  },
});

const { msisdnStatusFetch, fetchMSISDNStatusFailure, fetchMSISDNStatusSuccess } = msisdnStatusSlice.actions;

const selectGaugeValues = (msisdnStatus) =>
  msisdnStatus?.result?.total ? formatGaugeValues(msisdnStatus.result.total) : null;

const selectStatusDetails = (msisdnStatus) =>
  msisdnStatus?.result?.details ? getDetails(msisdnStatus.result.details, msisdnStatus.offername) : null;

const selectGaugeDetails = createSelector(state => state.MSISDNStatus, (msisdnStatus) => ({
  gaugeData: selectGaugeValues(msisdnStatus.status),
  loading: msisdnStatus.loading
}));
const selectMSISDNStatus = createSelector(state => state.MSISDNStatus, (msisdnStatus) => ({
  statusDetails: selectStatusDetails(msisdnStatus.status),
  loading: msisdnStatus.loading
}));
const isThereStatusInfoSelector = createSelector(selectMSISDNStatus, s => !!s.statusDetails);

export default msisdnStatusSlice.reducer;
export { msisdnStatusFetch, fetchMSISDNStatusFailure, fetchMSISDNStatusSuccess, selectGaugeDetails, selectMSISDNStatus, isThereStatusInfoSelector };
