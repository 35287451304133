import { Col, Row, Input } from 'reactstrap';
import { useDashboard } from './useDashboard';
import { useRef } from 'react';

const SearchMSISDN = () => {
  const { executeSearch } = useDashboard();

  const inputRef = useRef(null);
  const searchMSISDN = () => {
    const msisdn = inputRef.current.value;
    executeSearch(msisdn);
  };

  return (<>
    <Row className="mt-3">
      <Col>
        <div className="input-group">
          <Input
            innerRef={inputRef}
            type="tel"
            className="form-control"
            aria-label="Buscar por número telefónico"
            aria-describedby="phoneSearcher"
            placeholder="Número telefónico"
            onKeyDown={e => e.key === 'Enter' && searchMSISDN()}
          />
          <button
            className="btn btn-primary"
            type="submit"
            id="phoneSearcher"
            aria-label="Realizar búsqueda"
            onClick={() => searchMSISDN()}
          >
            <i className="la la-search"></i>
          </button>
        </div>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <p className="text-muted">Si el numero pertenece a Pandamovil y está activo,
        la consulta te devolverá los datos del paquete en curso.</p>
      </Col>
    </Row>
  </>);
};

export default SearchMSISDN;
