import { Card, CardBody, Col, Container, Form, FormGroup, FormFeedback, Row, Label, Input, Button } from 'reactstrap';
import { useRecharge } from './useRecharge';
import { useOfferings } from '../../Components/Hooks/useOfferings';

const Recharge = () => {
  const { rechargeForm, loading, isSuccess, isError } = useRecharge();
  const { filteredOfferings, setPeriod, loading: loadingOfferings } = useOfferings();

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Recarga de saldo</h1>
        <Row className="justify-content-center">
          <Col xs={12} sm={6}>
            <Card>
              <CardBody>
                <Form onSubmit={e => {
                  e.preventDefault();
                  rechargeForm.handleSubmit();
                }}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="recharge-msisdn">Número</Label>
                        <Input id="recharge-msisdn"
                            onChange={rechargeForm.handleChange}
                            onBlur={rechargeForm.handleBlur}
                            invalid={
                              rechargeForm.touched.msisdn &&
                              !!rechargeForm.errors.msisdn
                            }
                            value={rechargeForm.values.msisdn || ''}
                            name="msisdn"
                            placeholder="4455667788"
                            type="tel"
                        />
                        { !!rechargeForm.errors.msisdn &&
                        <FormFeedback>{ rechargeForm.errors.msisdn }</FormFeedback>
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="recharge-offering-filter">Periodo</Label>
                        <Input id="recharge-offering-filter"
                            defaultValue={""}
                            onInput={e => setPeriod(e.target.value)}
                            name="msisdn"
                            type="select"
                        >
                            <option value="" disabled>Periodo</option>
                            <option value={'7d'}>7 días</option>
                            <option value={'15d'}>15 días</option>
                            <option value={'30d'}>30 días</option>
                            <option value={'12m'}>12 meses</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        { loadingOfferings && <Spinner size="sm" className="me-2" /> }
                        <Label for="recharge-offering-id">Plan</Label>
                        { !loadingOfferings && filteredOfferings && <>
                          <Input id="recharge-offering-id"
                              onChange={rechargeForm.handleChange}
                              onBlur={rechargeForm.handleBlur}
                              invalid={
                                rechargeForm.touched.offeringId &&
                                !!rechargeForm.errors.offeringId
                              }
                              value={rechargeForm.values.offeringId || ''}
                              name="offeringId"
                              type="select"
                          >
                            <option value="" disabled>Seleccionar oferta</option>
                            { filteredOfferings.map(offering =>
                                <option key={offering.offer_id} value={offering.offer_id}>{ offering.display_name }</option>)
                            }
                          </Input>
                          { !!rechargeForm.errors.offeringId &&
                          <FormFeedback>{ rechargeForm.errors.offeringId }</FormFeedback>
                          }</>
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2 justify-content-center">
                    <Col className="col-auto">
                      <Button type="button" color="primary" onClick={() => rechargeForm.submitForm()}>Recargar</Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default Recharge;
