import { useSelector } from 'react-redux';
import { selectHBBDetails } from '../../Components/Hooks/hbb/hbbReducer';
import { Badge, Card, CardBody, Spinner } from 'reactstrap';
import { useMemo } from 'react';
import CardSpinner from '../../Components/UI/CardSpinner';

const LineStatus = () => {
  const hbbDetails = useSelector(selectHBBDetails);
  const color = useMemo(() => {
    return hbbDetails?.status === 'Active' ? 'success' : 'light';
  }, [hbbDetails]);

  const status = useMemo(() => {
    if (hbbDetails?.status) {
      return hbbDetails?.status === 'Active' ? 'Línea Activa' : 'Línea Suspendida';
    } else {
      return '';
    }
  }, [hbbDetails]);

  return (<>
    { hbbDetails.loading && <CardSpinner /> }
    {
      !hbbDetails.loading && hbbDetails?.status &&
      <Card className="w-100"><CardBody>
        <h1 className="text-center">
          <Badge color={color}>
            { status }
          </Badge>
        </h1>
      </CardBody></Card>
    }
  </>);
};

export default LineStatus;
