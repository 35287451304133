import { useMutation } from '@tanstack/react-query';
import { postSuspendTraffic } from '../../../helpers/fakebackend_helper';

export const useSuspendTraffic = () => {
  const { mutateAsync: suspendTraffic, data: suspendTrafficResult, isPending: loadingSuspendTraffic, isSuccess, isError } = useMutation({
    mutationFn: async (msisdn) => await postSuspendTraffic({ msisdn, reason: 1 })
  });

  return { suspendTraffic, loadingSuspendTraffic, isSuccess, isError };
};
