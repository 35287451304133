import { useFormik } from 'formik';
import { useState } from 'react';
import { Button, Form, FormFeedback, Input, InputGroup, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import * as Yup from "yup";
import { useUpdateProperties } from '../../Components/Hooks/update-properties/useUpdateProperties';

const ICCIDForm = () => {
  const { updateICCID } = useUpdateProperties();
  const [iccid, setICCID] = useState('');

  const iccidForm = useFormik({
      initialValues: { iccid },
      validationSchema: Yup.object({
        iccid: Yup.string().required("Por favor ingrese el ICCID"),
      }),
      onSubmit: (iccid) => {
        onModify(iccid);
      },
  });

  const onModify = ({ iccid }) => {
    Swal.fire({
      icon: 'warning',
      title: `¿Modificar ICCID a ${iccid}?`,
      showCancelButton: true,
      confirmButtonText: 'Modificar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: 'true',
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await updateICCID(iccid);
          return { updated: true };
        } catch (error) {
          return { updated: false };
        }
      },
    }).then(result => {
      if (result.isConfirmed) {
        if (result.value.updated) {
          Swal.fire({
            title: 'ICCID actualizado',
            icon: 'success'
          });
        } else {
          Swal.fire({
            title: 'ICCID no pudo ser actualizado',
            icon: 'error'
          });
        }
      }
    });
  };

  return (
    <Form onSubmit={(e) => {
      e.preventDefault();
      iccidForm.handleSubmit();
    }}>
      <Label>
        Cambio de SIM
      </Label>
      <InputGroup className="mb-3">
        <Input placeholder="ICCID"
          name="iccid"
          className="form-control"
          onChange={iccidForm.handleChange}
          onBlur={iccidForm.handleBlur}
          value={iccidForm.values.iccid || ""}
          invalid={ iccidForm.touched.iccid && !!iccidForm.errors.iccid }
        />
        <Button color="primary" type="submit">Modificar</Button>
      </InputGroup>
      { iccidForm.touched.iccid && !!iccidForm.errors.iccid &&
        <FormFeedback type="invalid">{iccidForm.errors.iccid}</FormFeedback>
      }
    </Form>
  );
};

export default ICCIDForm;
