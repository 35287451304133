import { useMutation } from '@tanstack/react-query';
import { postUpdateProperties } from '../../../helpers/fakebackend_helper';
import {  useSelector } from 'react-redux';
import { selectCurrentMSISDN } from '../../../Pages/Dashboard/searchTextReducer';

export const useUpdateProperties = () => {
  const msisdn = useSelector(selectCurrentMSISDN);
  const { mutateAsync: updateICCID, isPending: loadingUpdateICCID } = useMutation({
    mutationFn: async (iccid) => {console.log(iccid,msisdn); await postUpdateProperties({ iccid,msisdn })}
  });

  return { updateICCID, loadingUpdateICCID };
};
