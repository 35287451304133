import { useMutation } from '@tanstack/react-query';
import { postMSISDNStatus } from '../../../helpers/fakebackend_helper';
import { useDispatch } from 'react-redux';
import { fetchMSISDNStatusFailure, fetchMSISDNStatusSuccess, msisdnStatusFetch } from './msisdnStatusReducer';

export const useMSISDNStatus = () => {
  const dispatch = useDispatch();

  const { mutate: getMSISDNStatus } = useMutation({
    mutationFn: async (msisdn) => await postMSISDNStatus({ msisdn }),
    onMutate: () => dispatch(msisdnStatusFetch()),
    onSuccess: (res) => dispatch(fetchMSISDNStatusSuccess(res)),
    onError: () => dispatch(fetchMSISDNStatusFailure())
  });

  return { getMSISDNStatus };
};
