import { Card, CardBody, CardSubtitle, CardTitle, Col, Row, Spinner, Table } from 'reactstrap';
import { selectHBBDetails } from '../../Components/Hooks/hbb/hbbReducer';
import { useOfferings } from '../../Components/Hooks/useOfferings';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import CardSpinner from '../../Components/UI/CardSpinner';

const Segment = () => {
  const hbbDetails = useSelector(selectHBBDetails);
  const { offerings } = useOfferings();

  const primaryOffering = useMemo(() => {
    if (offerings && hbbDetails.primaryOfferingId) {
      return offerings[hbbDetails.primaryOfferingId] || '-';
    }
  }, [offerings, hbbDetails]);

  return (<>
    { hbbDetails?.loading && <CardSpinner /> }
    { !hbbDetails?.loading && hbbDetails.primaryOfferingId &&
      <Card className="w-100">
        <CardBody className="d-flex align-items-center">
          <Table borderless size="sm">
            <tbody>
              <tr>
                <th scope="row">Oferta primaria</th>
                <td>{ primaryOffering }</td>
              </tr>
              <tr>
                <th scope="row" colSpan="2">Información de SIM</th>
              </tr>
              <tr>
                <th scope="row">IMSI:</th>
                <td>{ hbbDetails?.imsi }</td>
              </tr>
              <tr>
                <th scope="row">ICCID:</th>
                <td>{ hbbDetails?.iccid }</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    }
  </>);
};

export default Segment;
