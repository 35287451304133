import { useSelector } from 'react-redux';
import { userInfoSelector } from '../../Components/Hooks/user-info/userInfoReducer';
import { Card, CardBody, Spinner, Table } from 'reactstrap';
import { selectCurrentMSISDN } from './searchTextReducer';
import CardSpinner from '../../Components/UI/CardSpinner';

const UserInfo = () => {
  const userInfo = useSelector(userInfoSelector);
  const currentMSISDN = useSelector(selectCurrentMSISDN);

  return (<>
    { userInfo?.loading && <CardSpinner /> }
    { !userInfo?.loading && userInfo.name &&
      <Card className="w-100">
        <CardBody className="d-flex align-items-center">
          <Table borderless size="sm">
            <tbody>
              <tr>
                <th scope="row">Nombre</th>
                <td>{ userInfo.name }</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{ userInfo.email }</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    }
    { !userInfo?.loading && !userInfo.name && currentMSISDN &&
      <Card className="w-100">
        <CardBody className="d-flex justify-content-center align-items-center">
          <h6>Usuario no registrado</h6>
        </CardBody>
      </Card>
    }
  </>);
};

export default UserInfo;
