import { useSelector } from 'react-redux';
import { Card, CardBody, Spinner, Table } from 'reactstrap';
import { selectBrand, selectDeviceFeatures, selectIMEI, selectLoading, selectModel } from '../../Components/Hooks/device-info/deviceInfoReducer';
import CardSpinner from '../../Components/UI/CardSpinner';

const DeviceInfo = () => {
  const brand = useSelector(selectBrand);
  const model = useSelector(selectModel);
  const deviceFeatures = useSelector(selectDeviceFeatures);
  const imei = useSelector(selectIMEI);
  const loading = useSelector(selectLoading);

  return (<>
    { loading && <CardSpinner /> }
    { !loading && (brand || model || deviceFeatures || imei) &&
      <Card className="w-100">
        <CardBody>
          <Table borderless size="sm">
            <tbody>
              <tr>
                <th scope="row">Marca</th>
                <td>{ brand || '-' }</td>
              </tr>
              <tr>
                <th scope="row">Modelo</th>
                <td>{ model || '-' }</td>
              </tr>
              <tr>
                <th scope="row">Sistema operativo</th>
                <td>{ deviceFeatures?.os || '-' }</td>
              </tr>
              <tr>
                <th scope="row">Tipo dispositivo</th>
                <td>{ deviceFeatures?.deviceType || '-' }</td>
              </tr>
              <tr>
                <th scope="row">IMEI</th>
                <td>{ imei?.imei || '-' }</td>
              </tr>
              <tr>
                <th scope="row">Homologado</th>
                <td>{ imei?.homologated || '-' }</td>
              </tr>
              <tr>
                <th scope="row">Status</th>
                <td>{ imei?.status || '-' }</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    }
  </>);
};

export default DeviceInfo;
