import { createSelector, createSlice } from '@reduxjs/toolkit';

const commentsSlice = createSlice({
  name: 'comments',
  initialState: {
    comments: [],
    loading: false,
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    loadingNewComment: false,
  },
  reducers: {
    resetComments: (state) => {
      state.comments = [];
      state.currentPage = 0;
      state.totalPages = 0;
      state.totalItems = 0;
    },
    fetchComments: (state) => {
      state.loading = true;
      state.currentPage = 0;
      state.totalPages = 0;
      state.totalItems = 0;
    },
    fetchCommentsSuccess: (state, action) => {
      state.loading = false;
      const { data, current_page, last_page, total } = action.payload.result;
      state.comments = current_page === 1 ? data : [...state.comments, ...(data ?? [])];
      state.currentPage = (current_page ?? 1) - 1;
      state.totalPages = last_page ?? 0;
      state.totalItems = total ?? 0;
    },
    fetchCommentsFailure: (state) => {
      state.loading = false;
      state.currentPage = 0;
      state.totalPages = 0;
      state.totalItems = 0;
    },
    addNewComment: (state) => {
      state.loadingNewComment = true;
    },
    addNewCommentSuccess: (state) => {
      state.loadingNewComment = false;
    },
    addNewCommentFailure: (state) => {
      state.loadingNewComment = false;
    },
  },
});

export const selectLoading = createSelector(state => state.Comments, (d) => d?.loading);
export const selectComments = createSelector(state => state.Comments, (d) => d?.comments);
export const selectCommentsPage = createSelector(state => state.Comments, (d) => ({
  totalItems: d?.totalItems,
  totalPages: d?.totalPages,
  currentPage: d?.currentPage,
  nextPage: d?.currentPage + 1 !== d?.totalPages ? d?.currentPage + 1 : null,
  hasNext: (d?.totalPages || 0) - ((d?.currentPage || 0) + 1) > 0,
}));
export const selectLoadingAddNewComment = createSelector(state => state.Comments, d => d.loadingNewComment);

export const { fetchComments, fetchCommentsSuccess, fetchCommentsFailure, addNewComment, addNewCommentSuccess, addNewCommentFailure, resetComments } = commentsSlice.actions;
export default commentsSlice.reducer;
