import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { Link } from "react-router-dom";
import MyBalanceModal from "../UI/MyBalanceModal";
import { useLoggedIn } from "../Hooks/useLoggedIn";

const ProfileDropdown = () => {
  const { userInfo, logout } = useLoggedIn();

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  //Balance modal
  const [isBalanceModalOpened, setIsBalanceModalOpened] = useState(false);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
            { userInfo?.display_name }
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">¡Bienvenido { userInfo?.display_name }!</h6>
          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link to={"/perfil"} className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle"> Perfil</span>
            </Link>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link className="dropdown-item" onClick={() => logout()}>
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Cerrar sesión
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <MyBalanceModal
        isOpen={isBalanceModalOpened}
        onClose={() => setIsBalanceModalOpened(false)}
        onToggle={() => setIsBalanceModalOpened(false)}
      />
    </React.Fragment>
  );
};

export default ProfileDropdown;
