export const formatDate = (dateStr) => {
  const year	= dateStr.substring(0, 4);
  const month	= dateStr.substring(4, 6);
  const date	= dateStr.substring(6, 8);
  const dateformated=new Date(year+"/"+month+"/"+date);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return dateformated.toLocaleDateString('es-MX', options);
};

export const formatDateTime = (dateStr) => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 7);
  const date = dateStr.substring(8, 10);

  const dateFormatted = new Date(year, month, date);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  return dateFormatted.toLocaleDateString('es-MX', options);
};

export const formatDateWithTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return date.toLocaleString('es-MX', options);
};
