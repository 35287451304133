import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsPage, postComment } from '../../../helpers/fakebackend_helper';
import { addNewComment, addNewCommentFailure, addNewCommentSuccess, fetchComments, fetchCommentsFailure, fetchCommentsSuccess } from './commentsReducer';
import { selectCurrentMSISDN } from '../../../Pages/Dashboard/searchTextReducer';

export const useComments = () => {
  const dispatch = useDispatch();
  const msisdn = useSelector(selectCurrentMSISDN);
  const perpage = 10;

  const { mutate: getComments } = useMutation({
    mutationFn: async ({ page }) => await getCommentsPage({ msisdn, from: '', to: '', page: page + 1, perpage }),
    onMutate: () => dispatch(fetchComments()),
    onSuccess: (res) => {
        dispatch(fetchCommentsSuccess(res))
    },
    onError: () => dispatch(fetchCommentsFailure()),
  });

  const { mutateAsync: createComment } = useMutation({
    mutationFn: async ({ comment }) => await postComment({ msisdn, comment }),
    onMutate: () => dispatch(addNewComment()),
    onSuccess: async () => {
      await getComments({ page: 0 });
      dispatch(addNewCommentSuccess());
    },
    onError: () => dispatch(addNewCommentFailure()),
  });

  return { getComments, createComment };
};
