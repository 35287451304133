import { useMutation } from '@tanstack/react-query';
import { postBlockIMEI, postUnblockIMEI } from '../../../helpers/fakebackend_helper';

export const useIMEI = () => {
  const { mutateAsync: blockIMEI } = useMutation({
    mutationFn: async (imei) => await postBlockIMEI({ imei }),
  });

  const { mutateAsync: unblockIMEI } = useMutation({
    mutationFn: async (imei) => await postUnblockIMEI({ imei })
  });

  return { blockIMEI, unblockIMEI };
};
