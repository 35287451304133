import { Card, CardBody, Col, Container, Form, FormGroup, FormFeedback, Row, Label, Input, Button } from 'reactstrap';
import SearchMSISDN from './SearchMSISDN';
import DashboardGauge from './DashboardGauge';
import Segment from './Segment';
import LineStatus from './LineStatus';
import DeviceInfo from './DeviceInfo';
import Sums from './Sums';
import Activation from './Activation';
import { useDashboard } from './useDashboard';
import UserInfo from './UserInfo';
import IMEI from './IMEI';
import Followup from './Followup';
import ICCIDForm from './ICCIDForm';

const Dashboard = () => {
  const { currentMSISDN, hasDataAndReady } = useDashboard();

  return (
    <div className="page-content">
      <Container fluid>
        <h1 className="text-center">Datos generales de la cuenta</h1>
        <Row>
          <Col xs={12} sm={6} xxl={3} className="d-flex">
            <DCard>
              <h3>Búsqueda de número</h3>
              <SearchMSISDN />
            </DCard>
          </Col>
          <Col xs={12} sm={6} xxl={3} className="d-flex">
            <Segment />
          </Col>
          <Col xs={12} sm={6} xxl={3} className="d-flex">
            <UserInfo />
          </Col>
          <Col xs={12} sm={6} xxl={3} className="d-flex">
            <DeviceInfo />
          </Col>
        </Row>

        <Row>
          <Col xl={3}>
            <Row>
              <Col xs={12}>
                <LineStatus />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DashboardGauge />
              </Col>
            </Row>
          </Col>
          <Col xl={9}>
            <Sums />
          </Col>
        </Row>

        { currentMSISDN && hasDataAndReady &&
          <Row>
            <Col xxl={6}>
              <h3 className="text-center">Operaciones por robo o extravío</h3>
              <Card>
                <CardBody>
                  <Row className="my-3">
                    <Col lg={6}>
                      <Activation />
                    </Col>

                    <Col lg={6} className="mt-4 mt-sm-0">
                      <Row className="mb-5">
                        <Col>
                          <ICCIDForm/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <IMEI />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xxl={6}>
              <h3 className="text-center">Seguimiento de Usuario</h3>
              <Followup />
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
};

function DCard({ children }) {
  return (
    <Card>
      <CardBody>
        { children }
      </CardBody>
    </Card>
  );
}

export default Dashboard;
