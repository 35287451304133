import React from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { useLoggedIn } from '../../../Components/Hooks/useLoggedIn';

const AccesosTab = () => {
  const { userInfo } = useLoggedIn();

  return (
    <React.Fragment>
      <Form>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="firstnameInput" className="form-label">
                Nombre
              </Label>
              <Input
                value={userInfo?.display_name ?? ''}
                type="text"
                className="form-control"
                id="firstnameInput"
                placeholder="Ingrese su nombre"
                disabled
              />
            </div>
          </Col>
          {/*<Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="lastnameInput" className="form-label">
                Apellido(s)
              </Label>
              <Input
                type="text"
                className="form-control"
                id="lastnameInput"
                placeholder="Ingrese su(s) apellido(s)"
                defaultValue=""
              />
            </div>
          </Col>*/}
          {/*<Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="phonenumberInput" className="form-label">
                Número telefónico
              </Label>
              <Input
                type="text"
                className="form-control"
                id="phonenumberInput"
                placeholder="Ingrese su número telefónico"
                defaultValue=""
              />
            </div>
            {/*<div className="form-check form-switch form-switch-lg">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked
              />
              <Label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Activo
              </Label>
            </div>
          </Col>*/}
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="emailInput" className="form-label">
                Email
              </Label>
              <Input
                value={userInfo?.email ?? ''}
                type="email"
                className="form-control"
                id="emailInput"
                placeholder="Ingrese su email"
                disabled
              />
            </div>
          </Col>
          {/*<Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="JoiningdatInput" className="form-label">
                Fecha de ingreso
              </Label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                }}
                defaultValue={new Date().toISOString()}
              />
            </div>
          </Col>*/}
          {/*<Row className="mt-4">
            <div className="fs-5">Condiciones comerciales</div>
            <Col xs={12} sm={8}>
              <CommissionsTable disabled />
            </Col>
            </Row>*/}
          {/*<Col lg={12} className="mt-4">
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-primary">
                Guardar cambios
              </button>
            </div>
          </Col>*/}
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default AccesosTab;
