import { createSelector, createSlice } from '@reduxjs/toolkit';

const hbbSlice = createSlice({
  name: 'hbbInfo',
  initialState: {
    hbb: null,
    loading: false,
  },
  reducers: {
    hbbFetch: (state) => {
      state.loading = true;
      state.hbb = null;
    },
    hbbFetchSuccess: (state, action) => {
      state.loading = false;
      state.hbb = action.payload;
    },
    hbbFetchFailure: (state, action) => {
      state.loading = false;
      state.hbb = null;
    },
  },
});

const selectHBBDetails = createSelector(state => state.HBBInfo, (hbbInfo) => {
  const hbbDetails = hbbInfo.hbb;
  return {
    loading: hbbInfo?.loading,
    primaryOfferingId: hbbDetails?.primaryOffering?.offeringId,
    iccid: hbbDetails?.information?.ICCID,
    imsi: hbbDetails?.information?.IMSI,
    status: hbbDetails?.status?.subStatus
  };
});
const imeiSelector = createSelector(state => state.HBBInfo, (info) => info?.hbb?.information?.IMEI);
const isThereAnyHBBInfoSelector = createSelector(state => state.HBBInfo, hbbInfo => !!hbbInfo.hbb);

const { hbbFetch, hbbFetchFailure, hbbFetchSuccess } = hbbSlice.actions;
export default hbbSlice.reducer;
export { hbbFetch, hbbFetchFailure, hbbFetchSuccess, selectHBBDetails, isThereAnyHBBInfoSelector, imeiSelector };
