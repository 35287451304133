import React from "react";
import { Row, Col, Label, Input } from "reactstrap";

const TaxInformationForm = () => {
  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 py-1">
          <div>
            <Label htmlFor="billingNameInput" className="form-label">
              Razón social / Nombre
            </Label>
            <Input
              type="text"
              className="form-control"
              id="billingNameInput"
              defaultValue={0}
            />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="rfcInput" className="form-label">
              RFC
            </Label>
            <Input
              type="text"
              className="form-control"
              id="rfcInput"
              defaultValue={0}
            />
          </div>
        </Col>
        <Col className="col-6 py-1">
          <div>
            <Label htmlFor="billingNameInput" className="form-label">
              Teléfono
            </Label>
            <Input
              type="text"
              className="form-control"
              id="billingNameInput"
              defaultValue={0}
            />
          </div>
        </Col>
        <Col className="col-12 py-1">
          <Label className="form-label">Uso de CFDI</Label>
          <select className="form-select">
            <option value disabled selected>
              Seleccione uso de CFDI
            </option>
          </select>
        </Col>
        <Col className="col-12 py-1">
          <div>
            <Label htmlFor="fichaInput" className="form-label">
              Cédula fiscal
            </Label>
            <Input type="file" className="form-control" id="fichaInput" />
          </div>
        </Col>
        <Col className="col-12 py-1">
          <div>
            <Label htmlFor="commentInput" className="form-label">
              Lista de emails
            </Label>
            <textarea
              type="file"
              className="form-control"
              id="commentInput"
              placeholder="Lista de correos electrónicos separados por coma a los cuales se les enviará la factura electrónica. Ej: usuario1@mail.com,usuario2@mail.com"
              rows="4"
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TaxInformationForm;
