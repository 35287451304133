import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import androidImg from "@images/panda/profile/logo-android.png";
import webImg from "@images/panda/profile/logo-web.png";
import AddAndroidDeviceModal from "./AddAndroidDeviceModal";
import { ApplicationsTable } from "./ApplicationsTable";

const ApplicationsTab = () => {
  const [isAndroidDeviceModalOpen, setIsAndroidDeviceModalOpen] =
    useState(false);
  const onAddWebPage = () => {};
  const onAddAndroidDevice = () => {
    setIsAndroidDeviceModalOpen(true);
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xs={12} sm={10}>
          <Row className="fs-1-1 text-primary px-2">
            Agregar un medio de venta
          </Row>
          <Row className="align-items-center">
            <Col xs={4} sm={3} md={2} className="p-2 h-100 text-center">
              <img
                src={androidImg}
                alt="android"
                width="80%"
                className="cursor-pointer "
                onClick={onAddAndroidDevice}
              />
              <span className="text-muted mb-4">Android</span>
            </Col>
            <Col xs={4} sm={3} md={2} className="p-2 h-100 text-center">
              <img
                src={webImg}
                alt="web"
                width="80%"
                className="cursor-pointer"
                onClick={onAddWebPage}
              />
              <span className="text-muted mb-4">Web</span>
            </Col>
          </Row>
          {isAndroidDeviceModalOpen && (
            <AddAndroidDeviceModal
              isOpen={isAndroidDeviceModalOpen}
              onClose={() => setIsAndroidDeviceModalOpen(false)}
            />
          )}
          <Row className="fs-1-1 text-primary px-2 mt-4">Medios de venta</Row>
          <ApplicationsTable />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ApplicationsTab;
