import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { postLogin } from "../../helpers/fakebackend_helper";
import {
  loginSuccess,
  apiError,
  doLogin,
} from "../../slices/auth/login/reducer";
import { TOKEN_KEY, USER_KEY, setAuthorization } from '../../helpers/api_helper';

export const useLogin = (navigation) => {
  const [userLogin, setUserLogin] = useState({ email: '', password: '' });
  const [passwordShow, setPasswordShow] = useState(false);
  const dispatch = useDispatch();

  const loginForm = useFormik({
      initialValues: {
        email: userLogin.email,
        password: userLogin.password,
      },
      validationSchema: Yup.object({
        email: Yup.string().required("Por favor ingrese su email"),
        password: Yup.string().required("Por favor ingrese su contraseña"),
      }),
      onSubmit: (user) => {
          loginUser(user, navigation);
      },
  });

  const loginUser = async (user, navigation) => {
    try {
      let response = postLogin({
          email: user.email,
          password: user.password,
        });


      dispatch(doLogin());
      const data = await response;

      if (data) {
        if (data.result.token) {
          sessionStorage.setItem(TOKEN_KEY, data.result.token);
          sessionStorage.setItem(USER_KEY, JSON.stringify(data.result.user));
          setAuthorization(data.result.token);
        }

        if (data.status === 200) {
          dispatch(loginSuccess(data));
          navigation("/");
        } else {
          dispatch(apiError(data));
        }
      }
    } catch (error) {
      dispatch(apiError(error));
    }
  };

  return { loginForm, passwordShow, setPasswordShow };
};
