import { useMutation } from '@tanstack/react-query';
import { postHBBQuery } from '../../../helpers/fakebackend_helper';
import { useDispatch } from 'react-redux';
import { hbbFetch, hbbFetchFailure, hbbFetchSuccess } from './hbbReducer';

export const useHBBQuery = () => {
  const dispatch = useDispatch();

  const { mutate: getHBBInfo } = useMutation({
    mutationFn: async (msisdn) => await postHBBQuery({ msisdn }),
    onMutate: () => dispatch(hbbFetch()),
    onSuccess: (res) => {
      if (res?.altanresponse?.responseSubscriber) {
        dispatch(hbbFetchSuccess(res.altanresponse.responseSubscriber))
      }
    },
    onError: () => dispatch(hbbFetchFailure()),
  });

  return { getHBBInfo };
};
