import React from "react";
import { Row, Col, Button } from "reactstrap";

import TaxInformationForm from "@Components/UI/Billing/TaxInformationForm";
import TaxAddressForm from "@Components/UI/Billing/TaxAddressForm";

const BillingTab = () => {
  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col xs={10} sm={8}>
          <Row className="fs-3 text-primary px-2">Información fiscal</Row>
          <TaxInformationForm />
          <Row className="fs-3 text-primary px-2 mt-4">Dirección fiscal</Row>
          <TaxAddressForm />
          <Button color="primary" className="my-4 py-3 w-100">
            Guardar cambios
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BillingTab;
