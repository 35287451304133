import { Navigate } from "react-router-dom";

//login
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";

//Panda
import Profile from "../Pages/Profile";
import Sales from "../Pages/Sales/Sales.jsx";
//Products
import Portability from "../Pages/Portability";
import QueryUF from '../Pages/Query/QueryUF';
import PreRegister from '../Pages/PreRegister/PreRegister';
import Recharge from '../Pages/Recharge/Recharge.jsx';
import Dashboard from '../Pages/Dashboard/Dashboard.jsx';

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/perfil", component: <Profile /> },
  { path: "/portabilidad", component: <Portability /> },
  { path: "/ventas", component: <Sales /> },

  { path: "/consulta-uf", component: <QueryUF /> },
  { path: "/pre-registro", component: <PreRegister /> },
  { path: "/recarga", component: <Recharge /> },

  {
    path: "/",
    exact: true,

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
